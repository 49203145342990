import { Component, OnInit } from "@angular/core";
import { isAfter, isBefore, isEqual } from "date-fns";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import "jquery";
import "src/assets/jconfirm/js/jquery-confirm";
import { GeneralService } from "../../../services/general.service";

declare let $: any;

@Component({
  selector: "app-receivables",
  templateUrl: "./receivables.component.html",
  styleUrls: ["./receivables.component.scss"],
})
export class ReceivablesComponent implements OnInit {
  recList: any = [];
  searchRecList: any = [];
  searchText: "";
  p: string | number = 0;
  loading: any = false;
  desc: any = false;
  searchVal: any = false;
  companyData: any;
  fromDate: any;
  toDate: any;
  actionButtons = true;

  permissions: any = [];
  patientList = [];
  specimenList = [];
  facilityList = [];
  yearList = [];

  totalBilled = 0;
  totalPaid = 0;
  totalArrears = 0;

  year = new Date().getFullYear();
  month = new Date().toLocaleString("default", { month: "long" });
  newStartDate: any;
  newEndDate: any;
  getBilledTotal: any;
  getArrearsTotal: any;
  getPaidTotal: any;
  getReportsTotal: any;
  getPatientTotal: any;
  getSpecimenTotal: any;
  getFacilitiesTotal: any;

  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService
  ) {}

  ngOnInit() {
    this.getReceivables();
    this.getComProfile();
    this.getPermissions();
  }

  user_array = (str) => {
    return str.trim().split(",");
   };

  singleCurrency = this.user_array(sessionStorage.getItem("currency"));
  currencyNme = this.singleCurrency[0];
  currencySym = this.singleCurrency[1];
  currencyBase = this.singleCurrency[2];

   // Get user permissions
//  getPermissions() {
//   this.permissions = {
//     receivables: {
//       view: false
//     },
//   };

//   this.auth
//     .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
//     .then(
//       (response: any) => {
//         this.permissions = response.permissions.permissions[0];
//         this.actionButtons = Object.keys(this.permissions.receivables).every((key) => {
//           return key == "create" ? false : this.permissions.receivables[key];
//         })
//       }
//     );
// }
getPermissions() {
  this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
    (response: any) => {
      this.permissions = response.permissions.permissions[0];
    },
    (error: any) => {

    }
  );
}
  /**
   * It gets the receivables from the API and assigns them to the recList variable
   */
  getReceivables() {
    this.spinner.show();
    this.auth.get("receivables").subscribe((response: any) => {
      const resData = response.data[0];
      this.getBilledTotal = response.total_bill
      this.getArrearsTotal = response.total_arreas
      this.getPaidTotal = response.total_paid
      this.getReportsTotal = response.total_reports
      this.getPatientTotal = response.total_patients
      this.getSpecimenTotal = response.total_specimens
      this.getFacilitiesTotal = response.total_facilities

      this.recList = resData;

      // this.searchRecList = this.yearList = resData.filter(
      //   (ele) =>
      //     new Date(ele.created_at).getFullYear() === this.year &&
      //     new Date(ele.created_at).toLocaleString("default", {
      //       month: "long",
      //     }) === this.month
      // );

      // this.getPatientList();
      // this.getSpecimenList();
      // this.getFacilityList();
      // this.getTotalBilled();
      // this.getTotalPaid();
      // this.getTotalArrears();
    });

    this.spinner.hide();
  }

  getInputDate() {
    if (this.newStartDate && !this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`receivables?start_date=${this.newStartDate}`).subscribe({
        next: (response: any) => {
          const resData = response.data[0];
          this.getBilledTotal = response.total_bill
          this.getArrearsTotal = response.total_arreas
          this.getPaidTotal = response.total_paid
          this.getReportsTotal = response.total_reports
          this.getPatientTotal = response.total_patients
          this.getSpecimenTotal = response.total_specimens
          this.getFacilitiesTotal = response.total_facilities
    
          this.recList = resData;
        }
      });
      console.log("New Start Date", this.newStartDate);

    } else if (this.newEndDate && !this.newStartDate) {
      this.searchVal = true;
      this.auth.get(`receivables?end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          const resData = response.data[0];
          this.getBilledTotal = response.total_bill
          this.getArrearsTotal = response.total_arreas
          this.getPaidTotal = response.total_paid
          this.getReportsTotal = response.total_reports
          this.getPatientTotal = response.total_patients
          this.getSpecimenTotal = response.total_specimens
          this.getFacilitiesTotal = response.total_facilities
    
          this.recList = resData;
        }
      });
      console.log("New End Date", this.newEndDate);

    } else if (this.newStartDate && this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`receivables?start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          const resData = response.data[0];
          this.getBilledTotal = response.total_bill
          this.getArrearsTotal = response.total_arreas
          this.getPaidTotal = response.total_paid
          this.getReportsTotal = response.total_reports
          this.getPatientTotal = response.total_patients
          this.getSpecimenTotal = response.total_specimens
          this.getFacilitiesTotal = response.total_facilities
    
          this.recList = resData;
        }
      });
      console.log("New Start Date", this.newStartDate);
      console.log("New End Date", this.newEndDate);
    } else {
      this.auth.get(`receivables`).subscribe({
        next: (response: any) => {
          const resData = response.data[0];
          this.getBilledTotal = response.total_bill
          this.getArrearsTotal = response.total_arreas
          this.getPaidTotal = response.total_paid
          this.getReportsTotal = response.total_reports
          this.getPatientTotal = response.total_patients
          this.getSpecimenTotal = response.total_specimens
          this.getFacilitiesTotal = response.total_facilities
    
          this.recList = resData;
        }
      });
      // Handle the case when start date or end date is missing.
      console.log("Please enter both start date and end date.");
    }
  }

  /**
   * It takes the date from the input fields and filters the recList array to only include the elements that have a
   * created_at date that is equal to or after the from date and equal to or before the to date
   * @param {Event} $event - Event - This is the event that is triggered when the form is submitted.
   */
  // onSearchSubmit($event: Event) {
  //   $event.preventDefault();
  //   const fromVal = $("#from").val();
  //   const toVal = $("#to").val();

  //   if (fromVal !== "" && toVal !== "") {
  //     this.searchVal = true;
  //     this.loading = true;

  //     const from = new Date(new Date(fromVal).toDateString());
  //     const to = new Date(new Date(toVal).toDateString());

  //     this.fromDate = from.toDateString();
  //     this.toDate = to.toDateString();

  //     this.searchRecList = this.recList.filter((ele) => {
  //       const createdAt = new Date(new Date(ele.created_at).toDateString());
  //       return (
  //         (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
  //         (isEqual(to, createdAt) || isBefore(createdAt, to))
  //       );
  //     });

  //     this.getPatientList();
  //     this.getSpecimenList();
  //     this.getFacilityList();
  //     this.getTotalBilled();
  //     this.getTotalPaid();
  //     this.getTotalArrears();
  //     this.loading = false;
  //   } else {
  //     this.generalService.emptyDateFieldError();
  //   }
  // }

  /**
   * The function sorts the array in ascending order if the variable desc is true, and in descending order if the variable
   * desc is false
   */
  // changeOrder() {
  //   if (this.desc) {
  //     /* Sorting the searchRecList array in ascending order. */
  //     this.searchRecList = this.searchRecList.sort((a, b) => {
  //       return a.arrears - b.arrears;
  //     });
  //     this.desc = false;
  //   } else {
  //     /* Sorting the searchRecList array in descending order. */
  //     this.searchRecList = this.searchRecList.sort((a, b) => {
  //       return b.arrears - a.arrears;
  //     });
  //     this.desc = true;
  //   }
  // }

  /**
   * It takes the list of search results and creates a new list of patients
   */
  // getPatientList() {
  //   const patients = [];
  //   for (const list of this.searchRecList) {
  //     if (!patients.includes(list.patient_name)) {
  //       patients.push(list.patient_name);
  //     }
  //   }

  //   this.patientList = patients;
  // }

  /**
   * It takes the list of search results and creates a list of unique specimen names
   */
  // getSpecimenList() {
  //   const specimens = [];
  //   for (const list of this.searchRecList) {
  //     const specimen = list.clinical_module_items[0].form_name;
  //     if (!specimens.includes(specimen)) {
  //       specimens.push(specimen);
  //     }
  //   }

  //   this.specimenList = specimens;
  // }

  /**
   * It loops through the searchRecList array, and if the requesting_facility property exists and is not already in the
   * facilities array, it pushes it into the facilities array
   */
  // getFacilityList() {
  //   const facilities = [];
  //   for (const list of this.searchRecList) {
  //     if (
  //       list.clinical_module_items[0].requesting_facility &&
  //       !facilities.includes(list.clinical_module_items[0].requesting_facility)
  //     ) {
  //       facilities.push(list.clinical_module_items[0].requesting_facility);
  //     }
  //   }

  //   this.facilityList = facilities;
  // }

  /**
   * It loops through the searchRecList array and adds the total_amount of each object to the totalBilled variable
   */

  // getTotalBilled() {
  //   let a = 0;
  //   for (const list of this.searchRecList) {
  //     a += list.total_amount;
  //   }

  //   this.totalBilled = a;
  // }

  /**
   * It loops through the searchRecList array and adds the amount_paid property of each object in the array to the variable
   * a
   */
  // getTotalPaid() {
  //   let a = 0;
  //   for (const list of this.searchRecList) {
  //     a += list.amount_paid;
  //   }

  //   this.totalPaid = a;
  // }

  /**
   * It loops through the searchRecList array and adds the arreas property of each object in the array to the totalArrears
   * variable
   */

  // getTotalArrears() {
  //   let a = 0;
  //   for (const list of this.searchRecList) {
  //     a += list.arreas;
  //   }

  //   this.totalArrears = a;
  // }

  /**
   * The function clears the search fields and resets the search results to the original list
   */
  clear() {
    this.searchVal = false;
    this.newStartDate = (" ");
    this.newEndDate = (" ");
    this.getInputDate();
    this.getBilledTotal();
    this.getArrearsTotal();
    this.getPaidTotal();
    this.getReportsTotal();
    this.getPatientTotal();
    this.getSpecimenTotal();
    this.getFacilitiesTotal();
  }

  /**
   * It loops through the array of objects, pushes the values of the properties of each object in the array into a new
   * array called data, creates a CSV file data in an array, creates a user-defined function to download CSV file, merges
   * the data with CSV, displays the created CSV data on the web browser, provides the name for the CSV file to be
   * downloaded and finally, removes the hidden element from the DOM
   */
  csvMe() {
    const csvFileData = [];

    /* Looping through the resData array and pushing the values of the properties of each object in the array into
      a new array called data. */
    for (const res of this.recList) {
      csvFileData.push({
        id: res.id,
        date: res.created_at,
        patient: res.patient_name,
        reportID: res.clinical_module_items[0].pathology_number,
        specimen: res.clinical_module_items[0].form_name,
        facility: res.clinical_module_items[0].requesting_facility,
        paid: res.amount_paid,
        arrears: res.arreas,
        billed: res.total_amount,
      });
    }
    // create CSV file data in an array
    let dataKey = null;
    let dataVal = null;

    dataVal = csvFileData.map((obj) => {
      dataKey = Object.keys(obj);
      return Object.values(obj);
    });

    // create a user-defined function to download CSV file
    let csv = `${dataKey}\n`;

    // merge the data with CSV
    dataVal.forEach((row) => {
      csv += row.join(",");
      csv += "\n";
    });
    // display the created CSV data on the web browser
    document.write(csv);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    // provide the name for the CSV file to be downloaded
    const date = new Date().toLocaleDateString("en-GB");
    hiddenElement.download = `Receivables-[${date}].csv`;
    hiddenElement.style.visibility = "hidden";
    hiddenElement.style.display = "none";
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
    location.reload();
  }

  /**
   * We're creating a new div element, appending it to the body, copying the contents of the element we want to print into
   * the new div, printing the new div, and then removing the new div from the body
   */
  printElement() {
    const elem = document.getElementById("contentToPrint");
    const domClone = elem.cloneNode(true);

    const $printSection = document.createElement("div");
    $printSection.style.padding = "15px 5px";
    $printSection.id = "printSection";
    document.body.appendChild($printSection);

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    $printSection.style.padding = "0px";
    $printSection.innerHTML = "";
  }

  /**
   * This function is used to get the company profile data from the database
   */
  getComProfile() {
    this.spinner.show();
    this.auth.get("facility_profile").subscribe(
      (response: any) => {
        if (response.company !== null) {
          this.companyData = response.company;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
}
